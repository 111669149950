@import '../../includes/all';

$heading-color: $text-color-light;
$stack-color: $text-color-light;

h1,
h2 {
  color: $heading-color;
}

#content {
  background-color: rgba(0, 0, 0, .1);
  position: absolute;
  left: 0;
  right: 0;
  padding: 30px 50px;
}

#stack {
  overflow: auto;
  position: relative;
  left: -50px;
  right: -50px;
  width: calc(100% + 100px);
  bottom: -44px;
  padding: 20px;
  background-color: rgba(0, 0, 0, .3);
  color: $stack-color;
  font-size: 12px;

  &:empty {
    display: none;
  }
}
